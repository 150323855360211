<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import { registerUser,loginByFacebook,loginByGoogle } from "@/utils/apis.js";

export default {
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      user: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
      },
      submitted: false,
      tryingToRegister: false,
      isRegisterError: false,
      title: "Signup",
      isTermsAgreed: false,
    };
  },
  validations: {
    user: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        typeof:(/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)
      },
    },
  },
  methods: {
    async registerHere(e) {
      e.preventDefault()
      this.tryingToRegister=true;
      const body = {
        first_name: this.user.firstname,
        last_name: this.user.lastname,
        email: this.user.email,
        password: this.user.password,
      }
      if(this.isTermsAgreed){
        const data = await registerUser(body);
        console.log("*&^%)",data);
        if (data.success) {
          this.$toasted.success("Registration Successful", {
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            action: {
              text: "Close",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
          this.$router.push("/login");
        }
        else{
          this.$toasted.error("Registration failed", {
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            action: {
              text: "Close",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
        }
      }
      else{
        this.$toasted.error("Please agree to our terms and conditions", {
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          action: {
            text: "Close",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      }
      this.tryingToRegister=false;
    },


    async useLoginByFacebook(){
      try{

        const res = await loginByFacebook();
        //var token = res.credential.accessToken;
        var user = res.user;
        console.log("Facebook login:", user);
        const name = user.displayName;
        let email = user.email;
        let phoneNumber = user.phoneNumber ? user.phoneNumber : ''
        const nameArray = name.split(" ");
        let firstName = nameArray[0];
        nameArray.forEach((item, index) => {
          if(index != 0 && index !=nameArray.length-1){
            firstName = firstName + " " + item;
          }
        })

        let lastName = nameArray[nameArray.length - 1];
        // let accessToken = res.credential.accessToken;
        // console.log(accessToken);
        

        const body = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: '',
        ph_no : phoneNumber,
        is_firebase:true,
        user_type:"owner"
      }

      const data = await registerUser(body);
      console.log("*&^%)",data);

      }
      catch(err){
         console.log(err);
         console.log('Login error!');

      }
    },

    async useLoginByGoogle(){

      try{

        const res = await loginByGoogle();
        //var token = res.credential.accessToken;
        let user = res.user;
        console.log("Google login:", user);
        const name = user.displayName;
        let email = user.email;
        let phoneNumber = user.phoneNumber ? user.phoneNumber : ''
        const nameArray = name.split(" ");
        let firstName = nameArray[0];
        nameArray.forEach((item, index) => {
          if(index != 0 && index !=nameArray.length-1){
            firstName = firstName + " " + item;
          }
        })

        let lastName = nameArray[nameArray.length - 1];
        // let accessToken = res.credential.accessToken;
        // console.log(accessToken);
        

        const body = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: '',
        ph_no: phoneNumber,
        is_firebase:true,
        user_type:"owner"
      }

      const data = await registerUser(body);
      console.log("*&^%)",data);




      }
      catch(err){
         console.log(err);
         console.log('Login error!');

      }
      
    }

  },
  mounted() {
    if (localStorage.getItem("app_refresh_token")) {
      this.$router.push("/");
    }
  },

};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center"></div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Signup Here</h5>
                  <p class="text-muted">Get your Rentnet Account</p>
                </div>
                <div class="p-2 mt-4">
                  <b-form>
                    <div class="row">
                      <div class="col-6">
                        <b-form-group
                          id="email-group"
                          label="First Name"
                          class="mb-3"
                          label-for="firstname"
                        >
                          <b-form-input
                            id="firstname"
                            v-model="user.firstname"
                            type="text"
                            placeholder="Enter First name"
                            :class="{
                              'is-invalid':
                                submitted && $v.user.firstname.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="submitted && !$v.user.firstname.required"
                            class="invalid-feedback"
                          >
                            Firstname is required.
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-6">
                        <b-form-group
                          id="email-group"
                          label="Last Name"
                          class="mb-3"
                          label-for="lastname"
                        >
                          <b-form-input
                            id="lastname"
                            v-model="user.lastname"
                            type="text"
                            placeholder="Enter Last name"
                            :class="{
                              'is-invalid':
                                submitted && $v.user.lastname.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="submitted && !$v.user.lastname.required"
                            class="invalid-feedback"
                          >
                            Firstname is required.
                          </div>
                        </b-form-group>
                      </div>
                    </div>

                    <b-form-group
                      id="fullname-group"
                      label="Email"
                      label-for="email"
                      class="mb-3"
                    >
                      <b-form-input
                        id="email"
                        v-model="user.email"
                        type="email"
                        placeholder="Enter email"
                        :class="{
                          'is-invalid': submitted && $v.user.email.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.email.required"
                          >Email is required.</span
                        >
                        <span v-if="!$v.user.email.email"
                          >Please enter valid email.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="password-group"
                      label="Password"
                      class="mb-3"
                      label-for="password"
                    >
                      <b-form-input
                        id="password"
                        v-model="user.password"
                        type="password"
                        placeholder="Enter password"
                        :class="{
                          'is-invalid': submitted && $v.user.password.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && !$v.user.password.required"
                        class="invalid-feedback"
                      >
                        Password is required.
                      </div>
                    </b-form-group>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="auth-terms-condition-check"
                        @click="isTermsAgreed = !isTermsAgreed"
                      />
                      <label
                        class="form-check-label"
                        for="auth-terms-condition-check"
                        >I accept
                        <a href="javascript: void(0);" class="text-dark"
                          >Terms and Conditions</a
                        ></label
                      >
                    </div>
                    <div class="mt-3 text-end">
                      <b-button v-if="!tryingToRegister" v-on:click="registerHere($event)" variant="primary" class="w-sm">
                        Register
                      </b-button>
                      <b-button v-if="tryingToRegister" variant="secondary" class="w-sm">
                        Registering
                      </b-button>
                    </div>

                    <div class="mt-4 text-center">
                      <div class="signin-other-title">
                        <h5 class="font-size-14 mb-3 title">Sign up using</h5>
                      </div>

                      <ul class="list-inline">
                        <li class="list-inline-item">
                          <div
                            @click="useLoginByFacebook"
                            class="social-list-item bg-primary text-white border-primary"
                          >
                            <i class="mdi mdi-facebook"></i>
                          </div>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="social-list-item bg-info text-white border-info"
                          >
                            <i class="mdi mdi-twitter"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <div
                            @click="useLoginByGoogle"
                            class="social-list-item bg-danger text-white border-danger"
                          >
                            <i class="mdi mdi-google"></i>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="mt-4 text-center">
                      <p class="text-muted mb-0">
                        Already have an account ?
                        <router-link
                          to="/"
                          class="fw-medium text-primary"
                          >Login</router-link
                        >
                      </p>
                    </div>
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
          </div>
          <!-- end col -->
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style lang="scss" module></style>
